<div *ngIf="unifiedProduct; else loadingIndicator" class="wrapper row-centered">
    <div class="left-side">
        <div>
            <h2>{{ unifiedProduct.paymentConfigs.text.header }}</h2>
            <h5>{{ unifiedProduct.paymentConfigs.text.subHeader }}</h5>

            <div class="plan-name-area row-centered" *ngIf="unifiedProduct?.productConfigs">
                <div class="plan">{{ unifiedProduct?.productConfigs?.product?.name }} includes</div>
            </div>

            <div class="feature-list">
                <div
                    class="row-centered feature"
                    *ngFor="let feature of unifiedProduct.productConfigs.product?.marketing_features">
                    <mat-icon class="smaller-icon">check_circle</mat-icon>
                    {{ feature.name }}
                </div>
            </div>
        </div>

        <div class="row-centered">
            <div class="old-price" *ngIf="appliedCoupon">
                ${{ unifiedProduct?.productConfigs?.price?.unit_amount / 100 | number: '1.2-2' }} / Month
            </div>
            <div class="price">
                <ng-container *ngIf="appliedCoupon; else oldPrice">
                    ${{ appliedCoupon?.discountedPrice / 100 | number: '1.2-2' }} / Month
                </ng-container>
                <ng-template #oldPrice>
                    ${{ unifiedProduct?.productConfigs?.price?.unit_amount / 100 | number: '1.2-2' }} / Month
                </ng-template>
            </div>
        </div>

        <div class="terms" *ngIf="unifiedProduct" [innerHTML]="unifiedProduct.paymentConfigs.text.body">
            <!--        By subscribing, you agree to our-->
            <!--        <a routerLink="/terms" target="_blank">Terms of Service</a> and-->
            <!--        <a routerLink="/privacy" target="_blank">Privacy Policy</a>.<br/>-->
        </div>
    </div>

    <div class="right-side">
        <payment-form
            [finishedLoadingCoupon]="finishedLoadingCoupon$ | async"
            [couponResponse]="unifiedProduct?.productConfigs?.couponResponse"
            [price]="unifiedProduct.productConfigs.price"
            [product]="unifiedProduct.productConfigs.product"></payment-form>
    </div>
</div>

<ng-template #loadingIndicator>
    <loading-indicator class="wrapper loading-indicator"></loading-indicator>
</ng-template>
