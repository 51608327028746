<div id='pricing-table-container'>
  <div class='pricing-table'>
    <div class='pricing-table-grid'>
      <div
        *ngFor='
          let item of plansService.productsAndPrices$ | async;
          let i = index
        '
        [class.highlighted]='i === 1'
        [style.animationDelay]="i * 0.2 + 's'"
        class='price-column'>
        <div class='content'>
          <div class='badge-area'>
            <div *ngIf='i === 1' class='badge highlighted-shimmer'>Most popular</div>
          </div>
          <div class='plan-name'>{{ item.product.name }}</div>
          <div class='price-container row-centered'>
            <span class='price'> ${{ item.price.unit_amount / 100 }} </span>
            <span class='price-period'>
              per
              <br />
              month
            </span>
          </div>
          <button
            class='plan-button'
            color='primary'
            mat-raised-button
            [featureFlag]="{
              features: [item.product.metadata?.app_plan + 'Feature'],
              featureFlagLogic: 'AND',
              featureFlagMode: 'disabled',
              negativeLogic: true,
            }"
            (click)='selectPlan(item)'
            #featureFlagDir='featureFlagDirective'>
            {{
            item.product.metadata.app_plan ===
            (profileService.user$ | async).plan
              ? 'Current plan'
              : 'Start trial'
            }}

            <!--                        {{  featureFlagDir.flagged ? 'Start trial' : 'Current plan' }} Leaving this here for now so we can copy it to more places. -->
          </button>
        </div>

        <div class='feature-list-container'>
          <div class='feature-list-header'>This includes:</div>
          <div class='feature-list'>
            <div
              *ngFor='let feature of item.product.marketing_features'
              class='feature row-centered'>
              <mat-icon>check_circle</mat-icon>
              <div>{{ feature.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
