import { Component, EventEmitter, Output } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { ProjectRecordingTypeEnum } from '../../../../../../../models/defines';
import { IDesignGroup } from '../../../../../../../models/design.model';

export interface IRecordingTypeButton {
    selected?: boolean;
    label: string;
    animationOptions: {
        path: string;
        autoplay: boolean;
        loop: boolean;
        speed?: number;
    };
    mostPopular?: boolean;
    value: ProjectRecordingTypeEnum;
}

@Component({
    selector: 'recording-type-step',
    templateUrl: './recording-type-step.component.html',
    styleUrl: './recording-type-step.component.scss',
})
export class RecordingTypeStepComponent {
    @Output('finished') finished = new EventEmitter<ProjectRecordingTypeEnum>();

    buttons: IRecordingTypeButton[] = [
        {
            label: 'Record Myself',
            animationOptions: {
                path: 'assets/animations/production/new-project/record-camera.json',
                autoplay: false,
                loop: true,
                speed: 0.6,
            },
            value: ProjectRecordingTypeEnum.CAMERA,
            mostPopular: true,
        },
        {
            label: 'Only My Voice',
            animationOptions: {
                path: 'assets/animations/production/new-project/record-mic.json',
                autoplay: false,
                loop: true,
                speed: 0.4,
            },
            value: ProjectRecordingTypeEnum.MIC,
        },
        {
            label: 'AI Voiceover',
            animationOptions: {
                path: 'assets/animations/production/new-project/record-ai-voice.json',
                autoplay: false,
                loop: true,
                speed: 0.6,
            },
            value: ProjectRecordingTypeEnum.AI_VOICE,
        },
    ];

    select(buttonSetup: IRecordingTypeButton) {
        // Cancel other buttons
        for (const button of this.buttons) {
            if (button !== buttonSetup) {
                button.selected = false;
            }
        }
        buttonSetup.selected = true;

        // Notify Selection
        this.finished.emit(buttonSetup.value);
    }

    private animationItems: AnimationItem[] = [];

    animationCreated(animation: AnimationItem, index: number): void {
        this.animationItems[index] = animation;

        const speed = this.buttons[index]?.animationOptions?.speed;
        if (speed) {
            this.animationItems[index]?.setSpeed(speed);
        }
    }

    startAnimation(index: number): void {
        this.animationItems[index]?.play();
    }

    stopAnimation(index: number): void {
        this.animationItems[index]?.stop();
    }
}
