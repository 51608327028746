import { Injectable } from '@angular/core';
import { FeedbackEnum, IFeedbackForm } from '../../models/defines';
import { ConfigurationAuthApiService } from '../api/auth/config-auth-api.service';
import { AnalyticsNotifierService } from './analytics-notifier.service';

@Injectable({
    providedIn: 'root',
})
export class FeedbackFormsService {
    FEEDBACK_SUBMITED_LOCALSTORAGE_KEY = 'feedback-status';
    feedbackForms: IFeedbackForm[];

    constructor(
        private configurationApi: ConfigurationAuthApiService,
        private analyticsNotfier: AnalyticsNotifierService
    ) {}

    public async getFeedbackConfigsAsync() {
        const apiResponse = await this.configurationApi.getFeedbackConfig();
        this.feedbackForms = apiResponse.feedbackForms;
    }

    /**
     * Get feedback config if we have it or fetch it from the server in case it doesn't exist
     * @param feedbackFormType FeedbackEnum
     * @returns
     */
    async getFeedbackConfig(feedbackFormType: FeedbackEnum): Promise<IFeedbackForm> {
        if (!this.feedbackForms) {
            await this.getFeedbackConfigsAsync();
        }
        return this.feedbackForms?.find((form) => form.id === feedbackFormType);
    }

    /**
     *  Set feedback submited to local storage array if it doesn't already exist
     * @param type
     */
    setFeedbackSubmittedToLocalStorage(type: FeedbackEnum): void {
        // Retrieve and parse the feedback status from localStorage, defaulting to an empty array
        const feedbackStatus: FeedbackEnum[] = this.getIsFeedbackSucmitedFromLocaStorage();

        // If the type is not already included, add it
        if (!feedbackStatus.includes(type)) {
            feedbackStatus.push(type);
            // Save the updated array back to localStorage
            localStorage.setItem(this.FEEDBACK_SUBMITED_LOCALSTORAGE_KEY, JSON.stringify(feedbackStatus));
        }
    }

    /**
     *  Check if the feedback submited status is already in the local storage
     * @param type
     */
    checkIsFeedbackSubmited(type: FeedbackEnum): boolean {
        const feedbackStatus: FeedbackEnum[] = this.getIsFeedbackSucmitedFromLocaStorage();
        return feedbackStatus.includes(type);
    }

    /**
     *   Check if the feedback submited status is already in the local storage
     */
    private getIsFeedbackSucmitedFromLocaStorage() {
        const feedbackStatus: FeedbackEnum[] = JSON.parse(
            localStorage.getItem(this.FEEDBACK_SUBMITED_LOCALSTORAGE_KEY) || '[]'
        );
        return feedbackStatus;
    }
}
