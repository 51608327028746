<mat-stepper #stepper (selectionChange)="stepChanged($event)" [linear]="isLinear" id="produce-wizard-stepper">
    <mat-step [stepControl]="subjectFormGroup" label="Idea">
        <div>
            <div #loadingIndicator *ngIf="isLoadingPromptSuggestions" class="spinner-holder">
                <loading-indicator></loading-indicator>
            </div>
        </div>
        <div>
            <div *ngIf="!isLoadingPromptSuggestions" id="idea-step">
                <span class="title-wrapper">
                    <h2>What type of video are you creating today?</h2>
                    <h6>Step 1/2</h6>
                </span>
                <div id="videoSubject">
                    <div>
                        <mat-chip-listbox [@fadeInOnEnter] aria-label="Tags Selection">
                            <mat-chip-option
                                (selectionChange)="changeVideoTag(tag)"
                                *ngFor="let tag of videoTags"
                                [selected]="tag === selectedVideoTag"
                                [value]="tag"
                                >{{ tag.title }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>

                    <div class="subject-input-wrapper">
                        <mat-form-field *ngIf="selectedVideoTag" [@fadeInOnEnter] class="subject-input">
                            <textarea
                                (keydown.enter)="nextStep()"
                                [(ngModel)]="userPrompt"
                                autocomplete="off"
                                cdkTextareaAutosize
                                matInput
                                placeholder="{{ selectedVideoTag.text }}">
                            </textarea>
                            <button
                                (click)="nextStep()"
                                [disabled]="!userPrompt || userPrompt.trim() === ''"
                                aria-label="Next"
                                class="icon-last"
                                color="primary"
                                fxHide
                                fxShow.gt-xs
                                id="subject-next-button"
                                mat-flat-button
                                matSuffix>
                                <span>Next</span>
                                <mat-icon matTextSuffix> navigate_next</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <!--  Next Button for mobile -->
                    <div
                        *ngIf="selectedVideoTag"
                        [@fadeInOnEnter]
                        class="mobile-next-button-wrapper"
                        fxHide.gt-xs
                        fxShow>
                        <button
                            (click)="nextStep()"
                            [disabled]="!userPrompt || userPrompt.trim() === ''"
                            aria-label="Next"
                            class="icon-last"
                            color="primary"
                            id="subject-next-button"
                            mat-flat-button
                            matSuffix>
                            <span>Next</span>
                            <mat-icon matTextSuffix> navigate_next</mat-icon>
                        </button>
                    </div>
                </div>

                <div style="min-height: 238px">
                    <div *ngIf="showSuggestions" [@fadeInOnEnter]>
                        <div style="height: 10px">
                            <mat-progress-bar *ngIf="!suggestions" mode="indeterminate"></mat-progress-bar>
                        </div>
                        <h6 class="suggestions-title">
                            {{ suggestions ? 'Your Tailored prompt suggestions' : 'Generating Suggestions' }}
                        </h6>
                        <mat-selection-list #goalsList [multiple]="false" class="suggestion-list">
                            <mat-list-option (click)="userPrompt = suggestion" *ngFor="let suggestion of suggestions"
                                >{{ suggestion }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step label="Type" *ngIf="toDisplayVideoTypeStep">
        <recording-type-step (finished)="recordingTypeSelected($event)"></recording-type-step>
    </mat-step>

    <!--  TODO: Formats were here-->
    <mat-step #finalLoading label="load">
        <div id="loader-step">
            <loading-with-swag #loadingWithSwagFinal [steps]="finalLoadingSteps"></loading-with-swag>
        </div>
    </mat-step>
</mat-stepper>

<div *ngIf="stepper" class="buttons-area">
    <button (click)="backStep()" *ngIf="stepper.selectedIndex > 0" mat-button mat-flat-button>
        <mat-icon style="font-size: 10px; width: 10px; height: 9px">arrow_back_ios </mat-icon>
        Back
    </button>
    <div class="dummy"></div>
</div>
