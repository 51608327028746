import {
    GenericStatusEnum,
    ICreative,
    ISceneAndEditTakes,
    MimeTypeEnum,
    ProjectStatusEnum,
    TranscriptStatusEnum,
} from './defines';
import { IBasicEditModel, IEditInDTO, IExportEditJob, ITrimLayers } from './project/edit/edit-model';
import { IScene, ISceneInDTO, ISceneOutDTO } from './project/scene-model';
import { IBranding } from './user';
import { IProjectIndexData } from 'src/app/services/state-management/project/shared-project-indexdb.service';
import { IDesignGroup } from './design.model';
import { VideoEditTake } from './job/edit-job-schema';
import { ITake, ITakeWithLottieComposed } from './project/take/take-model';
import { IComposeAndPlayOrNot } from './job/editor-defines';

export interface IBaseConvertor<TInput, ILocal, TOutput> {
    localToOut(client: ILocal, ...args: any[]): TOutput;
}

/**
 * IInput -> what comes from the server
 * ILocal -> what we transform from the server to do as we wish with him in the client. Do with the bad boy whatever you want
 * IOutput -> what we send back to the server
 */
export interface IConvertor<TInput, ILocal, TOutput> extends IBaseConvertor<TInput, ILocal, TOutput> {
    inToLocal(input: TInput, ...args: any[]): ILocal;
}

export interface IConvertorAsync<TInput, ILocal, TOutput> extends IBaseConvertor<TInput, ILocal, TOutput> {
    inToLocalAsync(input: TInput, ...args: any[]): Promise<ILocal>;
}

export interface IVideoSegmentProperties {
    hostEmail: string;
    projectId: string;
    sceneId: string;
    number: number;
    fileName: string;
    mimetype: MimeTypeEnum;
    position: number;
}

export enum ProjectPropertiesToUpdateEnum {
    PROJECT_TITLE = 'name',
    STATUS = 'statuses',
}

export enum ScenePropertiesToUpdateEnum {
    SCENE_TITLE = 'name',
    COPY = 'copy',
    IS_HIDDEN = 'isHidden',
    SELECTED_TAKE_ID = 'selectedTakeId',
}

export interface IExportUrls {
    exportUrl?: string;
    maxineUrl?: string;
}

export interface IBasicUserInfo {
    email: string;
    firstName: string;
    lastName?: string;
}

export interface IProjectStatuses {
    general: ProjectStatusEnum;
}

export interface ILaunchBranding {
    companyUrl: string;
    logo: string;
    colors: {
        colorPrimary?: string;
        colorSecondary?: string;
        colorExtra?: string;
    };
}

export interface ILaunchAnswers {
    name: string;
    email: string;
    question: string;
    shortQuestion: string;
    answer: string;
    branding: ILaunchBranding;
    projectCode: string;
}

export interface IBasicProjectData {
    id: string;
    name: string;
    user: IBasicUserInfo;
    title: string;
    designGroup: IDesignGroup;
    toMaxine: boolean;
    updatedAt: Date;
    statuses: IProjectStatuses;
    creative: ICreative;
    icon: string;
    createdAt: number;
    edits: IBasicEditModel[];
    launchAnswers: ILaunchAnswers;
    branding: IBranding;
}

export interface IProjectInDTO extends IBasicProjectData {
    scenes: ISceneInDTO[];
    edits: IEditInDTO[];
}

/// Doing project configs to make other properties / functions inside the constructor of IProject
export interface IProjectConfigs extends IBasicProjectData {
    scenes: IScene[];
    edits: IExportEditJob[];
    indexDBData: IProjectIndexData;
}

export interface IProject extends IProjectConfigs {}

export class Project implements IProject {
    id: string;
    name: string;
    user: IBasicUserInfo;
    title: string;
    designGroup: IDesignGroup;
    toMaxine: boolean;
    updatedAt: Date;
    statuses: IProjectStatuses;
    creative: ICreative;
    icon: string;
    createdAt: number;
    scenes: IScene[];
    edits: IExportEditJob[];
    indexDBData: IProjectIndexData;
    launchAnswers: ILaunchAnswers;
    branding: IBranding;

    constructor(projectConfigs: IProjectConfigs) {
        Object.assign(this, projectConfigs);
    }
}

export interface IProjectOutDTO extends IBasicProjectData {
    scenes: ISceneOutDTO[];
}
