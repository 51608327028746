import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InAppNotificationService {
    askToOpenStreamSettingsDialog = new EventEmitter<any>();
    private isLive = false;
    private mixPanelInitialized = new BehaviorSubject<boolean>(false);
    public mixPanelIntialized$ = this.mixPanelInitialized.asObservable();

    private _mixPanelIdentified$ = new BehaviorSubject<boolean>(false);
    public mixPanelIdentified$ = this._mixPanelIdentified$.asObservable();

    public isNavBarOpen = new BehaviorSubject<boolean>(false);

    constructor() {}

    openStreamSettingsDialog() {
        this.askToOpenStreamSettingsDialog.emit();
    }

    getIsLiveStatus() {
        return this.isLive;
    }

    setMixpanelInitalize(isInit: boolean) {
        this.mixPanelInitialized.next(isInit);
    }

    setMixpanelIdentified(isIdentified: boolean) {
        this._mixPanelIdentified$.next(isIdentified);
    }
}
