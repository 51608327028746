<div class='toast-body' [style.display]="this.state === 'inactive' ? 'none' : ''">

  <mat-icon class='smaller-icon'> info</mat-icon>

  <div class='main-area'>
    <div *ngIf='title' class='title' [class]='options.titleClass' [attr.aria-label]='title'>
      {{ title }}
    </div>

    <div *ngIf='message && options.enableHtml' role='alert'
         [class]='options.messageClass' class='text' [innerHTML]='message'>
    </div>
    <div *ngIf='message && !options.enableHtml' role='alert'
         [class]='options.messageClass' class='text html-text' [attr.aria-label]='message'>
      {{ message }}
    </div>
    <a *ngIf='!options.closeButton' class='btn btn-pink btn-sm' (click)='action($event)'>
      {{ undoString }}
    </a>

  </div>
  <mat-icon *ngIf='options.closeButton' class='toast-close-button smaller-icon' (click)='remove()'>close</mat-icon>

  <!--    </div>-->
  <!--    <div class='col-3 text-right'>-->
  <!--      <a *ngIf='!options.closeButton' class='btn btn-pink btn-sm' (click)='action($event)'>-->
  <!--        {{ undoString }}-->
  <!--      </a>-->
  <!--      <a *ngIf='options.closeButton' (click)='remove()' class='btn btn-pink btn-sm'>-->
  <!--        close-->
  <!--      </a>-->
  <!--    </div>-->
</div>
<div *ngIf='options.progressBar' class='toast-progress-wrap'>
  <div class='toast-progress' [style.width]="'calc(' +width + '% - 20px)'"></div>
</div>
