import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackEnum, IFeedbackForm, IFeedbackQuestion, QuestionTypeEnum } from 'src/app/models/defines';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';
import { FeedbackFormsService } from '../../../services/utils/feedback-forms.service';

export interface IFeedbackDialogCloseData {
    toCloseToasr: boolean;
    isFeedbackSubmited: boolean;
}

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
    EMPTY_ANSWER = 'No Answer';
    questionTypeEnum = QuestionTypeEnum;
    feedbackForm: IFeedbackForm;
    public checkboxState: { [questionId: string]: { [option: string]: boolean } } = {};
    protected readonly JSON = JSON;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { feedbackFormId: FeedbackEnum },
        public dialogRef: MatDialogRef<FeedbackDialogComponent>,
        private feedbacksService: FeedbackFormsService,
        private analyticsNotfier: AnalyticsNotifierService
    ) {}

    ngOnInit(): void {
        this.loadFormConfig();
    }

    /**
     * Load the feedback form config from the api/cache. Set all checkboxes to false as initial state
     */
    async loadFormConfig() {
        // Load feedback form config from server or cache
        this.feedbackForm = await this.feedbacksService.getFeedbackConfig(this.data.feedbackFormId);

        // Check if form exists
        if (!this.feedbackForm) {
            console.error('Feedback form not found', this.data.feedbackFormId);
            return;
        }
        // Preprocess checkbox state
        this.feedbackForm.questions.forEach((question) => {
            if (question.type === QuestionTypeEnum.SELECT) {
                this.checkboxState[question.id] = {};
                question.options.forEach((option) => {
                    // Set checkbox state to false
                    this.checkboxState[question.id][option] = false;
                });
            }
        });
    }

    /**
     * Get the users answers and iterate over them and send to mixpanel.
     * Closes the dialog on finish
     */
    public saveUserAnswers() {
        this.feedbacksService.setFeedbackSubmittedToLocalStorage(this.feedbackForm.id);
        const keyValuePairs: { [key: string]: string } = {};

        if (this.feedbackForm.questions?.length > 0) {
            for (const question of this.feedbackForm.questions) {
                console.log(question.value);
                // Check if it is an array of strings - Checkboxes
                if (typeof question.value === 'object') {
                    // Flatten selected checkboxes
                    // Put default value if it has no value
                    keyValuePairs[question.id] =
                        question.value?.length > 0 ? question.value.join(',') : this.EMPTY_ANSWER;
                } else {
                    keyValuePairs[question.id] = question.value || this.EMPTY_ANSWER;
                }
            }
            this.analyticsNotfier.pushTag({
                event: `Feedback Submitted - ${this.data.feedbackFormId}`,
                data: { ...keyValuePairs },
            });
        }

        this.closeDialog(true);
    }

    public closeDialog(toCloseToasr: boolean) {
        this.dialogRef.close(toCloseToasr);
    }

    /**
     * Update the state map on checkbox changes
     * @param question
     * @param option
     * @param event
     */
    public onCheckboxChange(question: IFeedbackQuestion, option: string, event: any): void {
        if (!this.checkboxState[question.id]) {
            this.checkboxState[question.id] = {};
        }

        this.checkboxState[question.id][option] = event?.checked ?? false;

        // Multiselect vs single select
        if (question.type === QuestionTypeEnum.SELECT) {
            // uncheck all other options
            Object.keys(this.checkboxState[question.id]).forEach((key) => {
                if (key !== option) {
                    this.checkboxState[question.id][key] = false;
                }
            });
        }

        // Update the actual question value array
        question.value = Object.keys(this.checkboxState[question.id]).filter(
            (key) => this.checkboxState[question.id][key]
        );
    }
}
