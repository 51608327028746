import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { WelcomeDialogComponent } from 'src/app/components/dialogs/welcome-dialog/welcome-dialog.component';
import { AuthenticationService } from './authentication.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import { FeatureGroupEnum, PlanEnum, User } from 'src/app/models/user';
import { FeatureApiService } from '../api/feature-api.service';
import { FeatureService } from '../feature-service.service';

export const WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT = 'welcomeDialogShown';

@Injectable({
    providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {
    constructor(
        private router: Router,
        private dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private profileService: ProfileService,
        private featureApiService: FeatureApiService,
        private featureService: FeatureService
    ) {}

    user: User;
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const welcomeDialogShown = localStorage.getItem(WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT);

        if (!welcomeDialogShown) {
            localStorage.setItem(WELCOME_DIALOG_SHOWN_KEY_DRAFTTTTTTT, 'false');
        }
        this.featureService
            .hasFeatureAccess('AB_mustpay_free')
            .pipe(take(2))
            .subscribe({
                //In case the user in 'mustpay' group hasAccess = true
                next: (hasAccess) => {
                    //The user belongs to group 'mustpay'.
                    if (hasAccess === true) {
                        this.authenticationService.user.featureGroup = FeatureGroupEnum.MUST_PAY;

                        //The user belongs to group 'free'.
                    } else {
                        this.authenticationService.user.featureGroup = FeatureGroupEnum.FREE;
                    }

                    this.featureApiService
                        .updateUserFeatureGroup(this.authenticationService.user)
                        .subscribe((updatedUser) => {
                            this.profileService.user$.next(updatedUser);
                        });
                },
                error: (error) => {
                    console.warn(`NO FEATURE FLAG DATA`);
                },
            });
        this.user = this.profileService.user$.value;

        if (!this.user) {
            return true;
        }

        return true;
    }
}
