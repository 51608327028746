import { Injectable } from '@angular/core';
import { IProductAndPayment, PlansApiService } from './api/plans-api.service';
import { BehaviorSubject, catchError, of, Subject } from 'rxjs';
import { Stripe } from 'stripe';
import { FeatureService } from './feature-service.service';

export interface IPlanDescribe {
    header: string;
    subHeader: string;
    body: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlansService {
    FETCH_INTERVAL = 15 * 60 * 1000;
    PROMO_CODE_LOCAL_STORAGE_KEY = 'promo-code';
    productsAndPrices$ = new BehaviorSubject<
        { product: Stripe.Product; price: Stripe.Price }[]
    >(null);
    unifiedProduct$ = new BehaviorSubject<IProductAndPayment>(null);
    private lastFetchTime = 0;
    paymentNotifier$ = new Subject<boolean>();
    promoCode: string = null;

    constructor(
        private plansApiService: PlansApiService,
        private featureService: FeatureService
    ) {}

    paymentDone(paymentData: boolean) {
        this.paymentNotifier$.next(paymentData);
    }

    /**
     *  Fetches the plans and prices from the server.
     *  If we have the plans in the cache, we don't fetch them again unless the cache has expired.
     *
     *  @returns The plans and prices fetched from the server.
     */
    getPlans() {
        const now = Date.now();
        if (
            !this.productsAndPrices$.value?.length ||
            now - this.lastFetchTime > this.FETCH_INTERVAL
        ) {
            this.plansApiService
                .listPlans()
                .pipe(
                    catchError((error) => {
                        console.error('Error fetching plans:', error);
                        // Optionally handle the error and provide a fallback value
                        return of(null);
                    })
                )
                .subscribe((plans) => {
                    plans.sort(
                        (a, b) =>
                            (a?.price?.unit_amount ?? 0) -
                            (b?.price?.unit_amount ?? 0)
                    );
                    this.productsAndPrices$.next(plans);
                    this.lastFetchTime = now;
                });
        }
    }

    getUnifiedPlan(promoCode: string = null) {
        // Check if we have a coupon code saved in local storage
        const foundCode =
            promoCode ??
            localStorage.getItem(this.PROMO_CODE_LOCAL_STORAGE_KEY);
        if (foundCode) {
            this.promoCode = foundCode;
        }

        this.plansApiService
            .unifiedPlan$(this.promoCode)
            .pipe(
                catchError((error) => {
                    console.error('Error fetching plans:', error);
                    // Optionally handle the error and provide a fallback value
                    return of(null);
                })
            )
            .subscribe((plan) => {
                this.unifiedProduct$.next(plan);
                // this.lastFetchTime = now;
            });
    }

    wipePromoCodeFromLocalStorage() {
        localStorage.removeItem(this.PROMO_CODE_LOCAL_STORAGE_KEY);
    }
}
