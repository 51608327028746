<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    [fullScreen]="true"
    type="ball-triangle-path">
</ngx-spinner>

<div id="page-wrapper">
    <!--  <app-navbar [showDestinations]="false"></app-navbar>-->
    <div id="inner-page">
        <app-side-nav
            #sideNav
            [class]="
                (sideNav._isOpen | async) ? 'opened' : 'closed'
            "></app-side-nav>
        <div class="right-side-wrapper">
            <router-outlet [name]="PANEL_OUTLET_URL"></router-outlet>
        </div>
    </div>

    <!--  <app-walkthrough-panel></app-walkthrough-panel>-->
</div>
