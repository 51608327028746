import { IEnvironment } from './IEnvironment';

export const environment: IEnvironment = {
    production: true,
    debugLog: true,
    enforceInvitations: true,

    prettyUrl: 'https://app.shuffll.com',
    api: 'https://app.shuffll.com:8080/api/v1',
    socketUrl: 'wss://app.shuffll.com:3200',
    googleFunctions: 'https://europe-west3-shuffll-beta-signups.cloudfunctions.net',
    mediasoup: 'mediasoup.shuffll.com',
    unleashConfig: {
        url: 'https://us.app.unleash-hosted.com/usbb1021/api/frontend',
        clientKey: 'shuffll-ec623d619bc6:production.b2d1a5d16c025d67b8d88a48188595ce8f72cb34fac47513d10bf9d6',
        appName: 'Shuffll',
        refreshInterval: 3 * 1000,
        cacheInterval: 3 * 1000,
    },
    stripe: {
        publishableKey:
            'pk_live_51JjfJ5JwhYEEanQx4TUudsWLmaXxhYyMd0hVpLsNbVICrYkUcWYUuPP2u4s31JhusePWanySRBNkHgvpa3FON1Ha002rAuaY1u',
    },
    intercomKey: 'hn18clua',
    hubspotProxyApi: 'https://us-central1-shuffll-backend.cloudfunctions.net/Hubspot-Proxy',
    mixpanelToken: 'ec0fa41b34138f88a537e2a11d0fe1af',
    trackUserEvents: true,
    baseCdnUrl: 'https://content.shuffll.com',
    googleClientId: '521811392962-9lb4ss9e3dvfuv7jr9lu1jn2gs4rhdvv',
    productFruitsWorkspaceId: '1Jvm0siQJoLCHTbf',
    version: '24.12.2',
};
