import { Directive, Input, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackEnum } from '../models/defines';
import { FeedbackDialogComponent } from '../components/dialogs/feedback-dialog/feedback-dialog.component';

export interface IFeedbackDialogData {
    feedbackFormId: FeedbackEnum;
}

@Directive({
    selector: '[appFeedbackPopupButton]',
})
export class FeedbackPopupButtonDirective {
    // Common dialog options as inputs
    @Input() width: string = '500px';
    @Input() minHeight: string = '560px';
    @Input() maxWidth: string = '95vw';
    @Input() disableClose: boolean = false; // Prevent dialog from closing  on click outside
    @Input() autoFocus: boolean = true; // Automatically focus on the first focusable element
    @Input() restoreFocus: boolean = true; // Restore focus to the trigger after closing
    @Input() panelClass: string | string[] = ''; // Add custom CSS classes to the dialog
    @Input() hasBackdrop: boolean = true; // Enable/disable the backdrop
    @Input() backdropClass: string = ''; // Custom class for the backdrop
    @Input() data: IFeedbackDialogData; // Data to pass to the dialog component

    constructor(private dialog: MatDialog) {}

    @HostListener('click') openFeedbackPopup() {
        const config: MatDialogConfig = {
            width: this.width,
            minHeight: this.minHeight,
            maxWidth: this.maxWidth,
            disableClose: this.disableClose,
            autoFocus: this.autoFocus,
            restoreFocus: this.restoreFocus,
            panelClass: this.panelClass,
            hasBackdrop: this.hasBackdrop,
            backdropClass: this.backdropClass,
            data: this.data,
        };

        this.dialog.open(FeedbackDialogComponent, config);
    }
}
