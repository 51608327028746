<div id="navbar" [class.colorful]="colorful">
  <div id="navbar-content">
    <div>
      <!--      <button appSignInSocial signInTo="twitch" color="primary" mat-raised-button>-->
      <!--        Test Me-->
      <!--      </button>-->
      <!--      <button mat-button (click)="toggleSideNav()" class="sidenav-button white" *ngIf="authentication.isAuthenticated()">-->
      <!--        <mat-icon>menu</mat-icon>-->
      <!--      </button>-->

      <button
        mat-button
        class="sidenav-button white"
        *ngIf="showGoToDashboard && authentication.isAuthenticated$ | async">
        <mat-icon>arrow_back_ios</mat-icon>
      </button>
      <div
        id="nav-logo"
        (click)="showGoToDashboard"
        class="pointer"
        routerLinkActive="router-link-active"></div>

      <!--      <div id="searchbox">-->
      <!--        <mat-icon>search</mat-icon>-->
      <!--        <input placeholder="Search">-->
      <!--      </div>-->
    </div>
    <div>
      <!--      <app-destinations-navbar *ngIf="authentication.isAuthenticated() && showDestinations"></app-destinations-navbar>-->
    </div>
    <div id="nav-icon-section" *ngIf="showRightSide">
      <button
        mat-button
        [matMenuTriggerFor]="userMenu"
        class="flex row flex-button">
        <current-user-img></current-user-img>
        <div id="nav-name" *ngIf="authentication.isAuthenticated$ | async">
          {{ authentication.getUser().name }}
        </div>
      </button>
      <mat-menu #userMenu="matMenu">
        <div class="menu-title" *ngIf="authentication.isAuthenticated$ | async">
          {{ authentication.getUser().name }}
          <span class="user-level">(⭐ Shuffller )</span>
        </div>
        <!--        <button mat-menu-invoice (click)="jumpToHost()">-->
        <!--          <mat-icon>videocam</mat-icon>-->
        <!--          Live Stream-->
        <!--        </button>-->

        <!--
        <button mat-menu-invoice>
          <mat-icon>person_outline</mat-icon>
          My Account
        </button> -->

        <!-- <button mat-menu-invoice>
          <mat-icon>settings</mat-icon>
          Settings
        </button> -->
        <a
          href="https://shuffll.com/faq"
          target="_blank"
          (click)="gtmService.notifyEvent('Navbar - Help')">
          <button mat-menu-item>
            <mat-icon>help</mat-icon>
            Help
          </button>
        </a>
        <!-- TODO: currently putting host - will need to be changed late -->
        <button
          mat-menu-item
          (click)="
            authentication.logout('/dashboard');
            gtmService.notifyEvent('Navbar - Log Out')
          ">
          <mat-icon>power_settings_new</mat-icon>
          Log Out
        </button>
      </mat-menu>

      <!--      <button-->
      <!--        mat-icon-button-->
      <!--        [matMenuTriggerFor]="notificationsMenu"-->
      <!--        *ngIf="authentication.isAuthenticated()"-->
      <!--      >-->
      <!--        <mat-icon>notifications_none</mat-icon>-->
      <!--      </button>-->
      <!--      <mat-menu #notificationsMenu="matMenu">-->
      <!--        <div class="no-notifications-wrapper">-->
      <!--          <mat-icon color="primary"> notifications_none</mat-icon>-->
      <!--          Hey you!<br/>-->
      <!--          You don't have any notifications.-->
      <!--        </div>-->
      <!--        &lt;!&ndash;        <button mat-menu-invoice>Item 1</button>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <button mat-menu-invoice>Item 2</button>&ndash;&gt;-->
      <!--      </mat-menu>-->

      <button
        id="creators-console"
        mat-raised-button
        (click)="goBack()"
        *ngIf="!(authentication.isAuthenticated$ | async)">
        <mat-icon>arrow_back_ios</mat-icon>
        Go Back
      </button>
      <span *ngIf="!this.config.isProduction">{{ this.config.version }}</span>

      <!-- <div id="sign-in-link" class="clickable link" >
      </div> -->
      <!--      Button to start streaming-->
      <!--      <button mat-icon-button (click)="jumpToHost()" ><mat-icon  class="link">linked_camera</mat-icon></button>-->
    </div>
  </div>
</div>
