import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/guards/authentication.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsNotifierService } from '../../../services/utils/analytics-notifier.service';
import { ROLE } from '../../../models/defines';
import { ProfileService } from 'src/app/services/show/profile.service';
import { Subscription } from 'rxjs';
import { RecordingProgressService } from 'src/app/services/studio/recording-progress.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { IntercomService } from 'src/app/services/intercom.service';
import { UserService } from 'src/app/services/api/auth/user.service';
import { FontsStoreService } from '../../../services/state-management/configs/fonts-store.service';
import { WelcomeDialogComponent } from 'src/app/components/dialogs/welcome-dialog/welcome-dialog.component';
import { PANEL_OUTLET_URL } from '../host/services/shared/constants/client/dashboard/dashboard.routes';

export const WELCOME_DIALOG_SHOWN_KEY = 'welcomeDialogShown';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    PANEL_OUTLET_URL = PANEL_OUTLET_URL;
    public selectedTabIndex = 1;
    fullScreen = false;
    sessionTimer: any = null;
    sessionTimeInMinutes: number = 0;
    isMobileUser: boolean;
    private subscriptions: Subscription = new Subscription();
    user: User;
    private isFeedbackToastShown = false; // Flag to track if feedback toastr has been shown
    private welcomeDialogRef: MatDialogRef<WelcomeDialogComponent>;

    constructor(
        public authenticationService: AuthenticationService,
        public dialog: MatDialog,
        public gtmService: AnalyticsNotifierService,
        public profileService: ProfileService,
        private intercomService: IntercomService,
        private userService: UserService,
        /// Do not remove font store service, we use it to initialize fonts the service's constructor
        private fontsStoreService: FontsStoreService
    ) {
        this.isMobileUser = this.userService.isMobileUser();
    }

    // @HostListener('window:popstate', ['$event'])
    // onPopState(event) {
    //   if (localStorage.getItem(WELCOME_DIALOG_SHOWN_KEY) === 'false') {
    //     window.location.reload();
    //   }
    // }
    ngAfterViewInit(): void {
        this.profileService.user$.subscribe((user) => {
            if (user) {
                this.user = user;
                if (!this.isMobileUser) {
                    this.intercomService.init(user);
                }
            }
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.intercomService.shutdown();
        if (this.sessionTimer) {
            clearInterval(this.sessionTimer);
        }
        this.subscriptions.unsubscribe();

        if (this.welcomeDialogRef) {
            this.welcomeDialogRef.close();
        }
    }

    protected readonly ROLE = ROLE;
}
