import { Injectable, NgZone } from '@angular/core';
import { AnimationItem, AnimationSegment } from 'lottie-web';
import {
    ILottieJsonMarker,
    ILottieMarker,
    IStartLoopMarkers,
} from '../models/defines';
import { LottieMarkerTypes } from '../models/lottie/lottie-defines';

interface ILottieFrameNotifier {
    startPlayFromFrame: number;
    loopCounter: number;
}

@Injectable({
    providedIn: 'root',
})
export class LottiePlayerService {
    private currentLoopCounter = 0;
    private currentPlayingFrame: number = 0;
    private startCountingFromFrame: number = 0;
    private totalElapsedTime: number = 0;
    constructor(private ngZone: NgZone) {}

    playAnimation(animationItem: AnimationItem) {
        this.ngZone.runOutsideAngular(() => {
            animationItem.play();
        });
    }

    pauseAnimation(animationItem: AnimationItem) {
        this.ngZone.runOutsideAngular(() => {
            animationItem.pause();
        });
    }

    resetCurrentLoopCounter() {
        this.currentLoopCounter = 0;
        this.currentPlayingFrame = 0;
    }

    goToAndPause(animationItem: AnimationItem, frameNumber: number) {
        if (!animationItem) {
            console.warn(
                `Could not go to and pause because animation item is null.`
            );
            return;
        }
        const frameToFreeze =
            frameNumber < animationItem.totalFrames
                ? frameNumber
                : animationItem.totalFrames - 1;
        this.ngZone.runOutsideAngular(() => {
            animationItem.goToAndStop(frameToFreeze, true);
        });
    }

    /**
     * Plays a Lottie animation and freezes at the last frame upon completion.
     * @param animationItem - The Lottie AnimationItem instance.
     * @param startFromFrame - Optional: The frame from which to start playing the animation; defaults to the beginning of the animation.
     */
    playAnimationAndFreezeAtEnd(
        animationItem: AnimationItem,
        startFromFrame?: number
    ): void {
        if (!animationItem) return;

        animationItem.addEventListener('complete', () => {
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndStop(animationItem.totalFrames - 1, true);
            });
        });

        // Play the animation from the specified frame or the beginning
        if (
            !isNaN(startFromFrame) &&
            startFromFrame >= animationItem.totalFrames
        ) {
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndStop(animationItem.totalFrames - 1, true);
            });
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            animationItem.goToAndPlay(startFromFrame ?? 0, true);
        });
    }

    /**
     * Plays the loop or freeze the last image of the animation if loop is not existed
     * @param animationItem
     * @param loopMark
     */
    playLoopIfExistedWhenStartDone(
        animationItem: AnimationItem,
        loopMark: ILottieMarker
    ) {
        if (loopMark) {
            animationItem.addEventListener('loopComplete', () => {
                if (loopMark) {
                    this.ngZone.runOutsideAngular(() => {
                        animationItem.goToAndPlay(LottieMarkerTypes.LOOP, true);
                    });
                }
            });
            animationItem.addEventListener('complete', () => {
                if (loopMark) {
                    this.ngZone.runOutsideAngular(() => {
                        animationItem.goToAndPlay(LottieMarkerTypes.LOOP, true);
                    });
                }
            });
        } else {
            // animationItem.addEventListener('complete', () => {
            //   this.ngZone.runOutsideAngular(() => {
            //     animationItem.goToAndStop(animationItem.totalFrames - 1, true);
            //   });
            // });
            animationItem.addEventListener('loopComplete', () => {
                this.ngZone.runOutsideAngular(() => {
                    animationItem.goToAndStop(
                        animationItem.totalFrames - 1,
                        true
                    );
                });
            });
        }
    }
    public playLoopMarkerAsLoop(animationItem: AnimationItem) {
        const markers =
            this.createLottieMarkersFromAnimationItem(animationItem);
        const loopMarker = markers?.find(
            (marker) => marker.type === LottieMarkerTypes.LOOP
        );

        if (!loopMarker) {
            this.playAnimationAndFreezeAtEnd(animationItem, 0);
            return;
        }
        this.playLoopIfExistedWhenStartDone(animationItem, loopMarker);
    }
    private createLottieMarkersFromAnimationItem(animationItem: AnimationItem) {
        const jsonMarkers: ILottieJsonMarker[] = this.getMarkers(animationItem);
        const parsedMarkersNames = jsonMarkers?.map((marker) => {
            return this.createLottieMarker(marker);
        });
        return parsedMarkersNames;
    }

    /**
     * Plays a loop from a specified frame in a Lottie animation.
     * @param animationItem - The Lottie AnimationItem instance.
     * @param frame - The frame from which to start playing the loop; default is from frame 0 of the loop.
     * @param loopMark - The marker representing the loop section.
     * @param startMark - Optional marker representing the starting point; default is the beginning of the animation.
     */
    playLoopFromFrame(
        animationItem: AnimationItem,
        frame: number = 0,
        loopMark: ILottieMarker,
        startMark?: ILottieMarker
    ) {
        if (!animationItem || !loopMark) {
            console.error(
                'Invalid arguments: animationItem or loopMark is null or undefined.'
            );
            return;
        }
        let startPlayInFrame: number = frame;
        const countingFromFrame = startMark ? startMark.startTimeInFrames : 0;
        /// If for an example it was pressed in 400 frames but the animation item only has 130
        if (startPlayInFrame > animationItem.totalFrames) {
            startPlayInFrame = startPlayInFrame - countingFromFrame;

            const frameDifference =
                startPlayInFrame % loopMark.durationInFrames;

            startPlayInFrame = loopMark.startTimeInFrames + frameDifference;
        }
        this.ngZone.runOutsideAngular(() => {
            animationItem.goToAndPlay(startPlayInFrame, true);
        });
        ///TODO: CHECK THIS - which event triggers !!!
        animationItem.addEventListener('complete', () => {
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndPlay(LottieMarkerTypes.LOOP, true);
            });
        });
        animationItem.addEventListener('loopComplete', () => {
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndPlay(LottieMarkerTypes.LOOP, true);
            });
        });
    }

    playFromFrame(animationItem: AnimationItem, startInFrame: number) {
        if (!animationItem) {
            console.warn(
                `Could not play from frame the animation because the animation item is null.`
            );
            return;
        }
        this.clearEventListeners(animationItem);
        const jsonMarkers = this.getMarkers(animationItem);
        const markers: IStartLoopMarkers = {
            startMark: null,
            loopMark: null,
        };
        this.initializeStartLoopMarkers(jsonMarkers, markers);

        if (markers.startMark) {
            if (markers.startMark.durationInFrames - startInFrame > 0) {
                this.ngZone.runOutsideAngular(() => {
                    animationItem.goToAndPlay(startInFrame, true);
                });
                this.playLoopIfExistedWhenStartDone(
                    animationItem,
                    markers.loopMark
                );
            }
            /// It means it needs to start in the middle of the loop sequence if existed because pressed on a frame that is bigger than start frame
            else if (markers.loopMark) {
                this.playLoopFromFrame(
                    animationItem,
                    startInFrame,
                    markers.loopMark,
                    markers.startMark
                );
            }
            /// No loop mark, but pressed on a frame that is bigger than start frame duration so freeze the end
            else {
                this.ngZone.runOutsideAngular(() => {
                    animationItem.goToAndStop(
                        animationItem.totalFrames - 1,
                        true
                    );
                });
            }
        }
        /// No start mark .
        else if (markers.loopMark) {
            this.playLoopFromFrame(
                animationItem,
                startInFrame,
                markers.loopMark
            );
        }
        // Note: No markers found in the JSON. Playing the animation and freezing at the end.
        else {
            this.playAnimationAndFreezeAtEnd(animationItem, startInFrame);
        }
    }

    /**
     * Plays the Lottie animation until a specified frame and freezes at the end.
     *
     * @param animationItem - The Lottie AnimationItem to be played.
     * @param startInFrame - The starting frame to play from.
     * @param endInFrame - The ending frame to freeze at.
     * @param playOrFreeze - true for playing, false for freezing
     */
    playOrFreezeAnimationBetweenFrames(
        animationItem: AnimationItem,
        startInFrame: number,
        endInFrame: number,
        playOrFreeze: boolean
    ) {
        if (!animationItem || startInFrame === null || endInFrame === null) {
            console.error(
                `Could not play lottie until frame, one of the arguemtns is null.`
            );
            return;
        }
        if (startInFrame > endInFrame) {
            console.error(
                `Could not play lottie until frame, end time is bigger than start time.
        Start time: ${startInFrame}, End time: ${endInFrame}`
            );
            return;
        }
        const jsonMarkers = this.getMarkers(animationItem);

        const markers: IStartLoopMarkers = {
            startMark: null,
            loopMark: null,
        };
        this.initializeStartLoopMarkers(jsonMarkers, markers);
        if (!markers.loopMark && !markers.startMark) {
        }
        this.caulculateFramesAndPlayOrFreeze(
            startInFrame,
            animationItem,
            endInFrame,
            markers,
            playOrFreeze
        );
    }

    startCountingPlayingTime(
        animationItem: AnimationItem,
        initialFrame: number
    ) {
        this.totalElapsedTime = 0;
        let totalElapsedTime = 0;

        // Initialize the total elapsed time with the initial frame offset
        if (initialFrame > 0) {
            totalElapsedTime = initialFrame;
        }

        // Add the enterFrame event listener
        animationItem.addEventListener('enterFrame', (event) => {
            totalElapsedTime++;

            this.totalElapsedTime = totalElapsedTime;
        });
    }

    /**
     * Returns the frame that the lottie is playing after calculating the number of loops he has been through
     */
    getCurrentPlayingFrame() {
        return this.totalElapsedTime;
    }
    private caulculateFramesAndPlayOrFreeze(
        startInFrame: number,
        animationItem: AnimationItem,
        endInFrame: number,
        markers: IStartLoopMarkers,
        playOrFreeze: boolean
    ) {
        this.totalElapsedTime = 0;

        const totalFramesToPlay = endInFrame - startInFrame;

        /// In case that the total frames are smaller than the animation frames duration
        // If it is smaller than 1, it means we won't have loop if existed more than 1
        const animationCountUntilStartFrame =
            totalFramesToPlay / animationItem.totalFrames;

        let totalFramesToPlayLoop = 0;
        let startPlayFromLottieFrameIn: number = 0;
        let remainingFramesToPlayAfterLoop: number = 0;
        let loopCounter: number = 0;
        /// Let's say that we clicked on frame 200
        /// We might have start marker which his duration is 30 frames
        /// And we might have loop marker which his duration is 50 frames
        if (markers.startMark) {
            if (startInFrame < markers.startMark.durationInFrames) {
                /// if we got here, we clicked on frame 15 for an example
                totalFramesToPlayLoop = Math.max(
                    totalFramesToPlay -
                        markers.startMark.durationInFrames -
                        startInFrame,
                    0
                );

                startPlayFromLottieFrameIn = startInFrame;
            } else {
                if (!markers.loopMark) {
                    /// If we got here, it means that we want to freeze at the end of the start marker
                    totalFramesToPlayLoop = 0;
                    startPlayFromLottieFrameIn =
                        markers.startMark.durationInFrames;
                } else {
                    if (
                        startInFrame <
                        markers.loopMark.durationInFrames +
                            markers.startMark.durationInFrames
                    ) {
                        /// If we got here, we clicked on a frame in the middle of a loop, like 70
                        // totalFramesToPlayLoop = totalFramesToPlay - startInFrame;
                        totalFramesToPlayLoop = totalFramesToPlay;
                        startPlayFromLottieFrameIn = startInFrame;
                    } else {
                        /// If we got here, we clicked on frame 200
                        /// meaning that we passed the start marker,
                        /// and we looped over the loop marker a few times, and now we are in the middle of it
                        /// Because we play only the loop marker from now on, we know that we need to calc how much frames we gonna play out of the lottie that are only loop
                        const gapFromStart =
                            startInFrame - markers.startMark.durationInFrames;
                        totalFramesToPlayLoop = totalFramesToPlay;
                        const args = this.calcLoop(
                            markers,
                            totalFramesToPlay,
                            totalFramesToPlayLoop,
                            null
                        );
                        remainingFramesToPlayAfterLoop =
                            args.remainingFramesToPlayAfterLoop;
                        loopCounter = args.loopCounter;
                        startPlayFromLottieFrameIn =
                            markers.loopMark.startTimeInFrames +
                            args.currentFrameInLoop;
                    }
                }
            }
        }

        if (!playOrFreeze) {
            /// We freeze here and that's it
            animationItem.goToAndStop(startPlayFromLottieFrameIn, true);
            return;
        }
        const args = this.calcLoop(
            markers,
            totalFramesToPlay,
            totalFramesToPlayLoop,
            startPlayFromLottieFrameIn
        );
        remainingFramesToPlayAfterLoop = args.remainingFramesToPlayAfterLoop;
        loopCounter = args.loopCounter;
        const segments: AnimationSegment[] = [];
        let firstSegments: AnimationSegment;
        if (loopCounter === 0) {
            firstSegments = [
                startPlayFromLottieFrameIn,
                startPlayFromLottieFrameIn + totalFramesToPlay,
            ];
        } else {
            firstSegments = [
                startPlayFromLottieFrameIn,
                markers.loopMark.startTimeInFrames +
                    markers.loopMark.durationInFrames,
            ];
        }
        segments.push(firstSegments);
        for (let i = 0; i < loopCounter; i++) {
            const loopSegment: AnimationSegment = [
                markers.loopMark.startTimeInFrames,
                markers.loopMark.startTimeInFrames +
                    markers.loopMark.durationInFrames,
            ];
            segments.push(loopSegment);
        }
        if (remainingFramesToPlayAfterLoop) {
            const lastSegment: AnimationSegment = [
                markers.loopMark.startTimeInFrames,
                markers.loopMark.startTimeInFrames +
                    remainingFramesToPlayAfterLoop,
            ];
            segments.push(lastSegment);
        }

        try {
            this.startCountingPlayingTime(animationItem, firstSegments[0]);
        } catch (error) {
            console.error(`COULD NOT !`, error);
        }
        const lastSegment = segments[segments.length - 1];
        const endFrame = lastSegment[1];

        // Add event listener to freeze the animation at the last frame
        animationItem.addEventListener('complete', () => {
            animationItem.goToAndStop(animationItem.totalFrames - 1, true);
        });
        animationItem.playSegments(segments, true);
        // this.playLoopWithCompletion(
        //   animationItem,
        //   remainingFramesToPlayInLoop,
        //   loopCounter,
        //   markers.loopMark,
        //   true
        // );
        return;
    }

    private calC(markers: IStartLoopMarkers, totalFramesToPlayLoop: number) {
        let remainingFramesToPlayInLoop = 0,
            currentFrameInLoop = 0,
            loopCounter = 0;

        const loopMarker = markers.loopMark;
        if (!loopMarker) {
            return {
                remainingFramesToPlayInLoop,
                currentFrameInLoop,
                loopCounter,
            };
        }
        loopCounter = Math.floor(
            totalFramesToPlayLoop / loopMarker.durationInFrames
        );

        currentFrameInLoop =
            totalFramesToPlayLoop % loopMarker.durationInFrames;
        remainingFramesToPlayInLoop =
            totalFramesToPlayLoop - loopCounter * loopMarker.durationInFrames;
        // loopMarker.durationInFrames - currentFrameInLoop;
        return { remainingFramesToPlayInLoop, currentFrameInLoop, loopCounter };
    }
    private calcLoop(
        markers: IStartLoopMarkers,
        totalFrames: number,
        totalFramesToPlayLoop: number,
        startInFrame: number | null
    ) {
        let remainingFramesToPlayAfterLoop = 0,
            currentFrameInLoop = 0,
            loopCounter = 0;

        const loopMarker = markers.loopMark;
        if (!loopMarker) {
            return {
                remainingFramesToPlayAfterLoop,
                currentFrameInLoop,
                loopCounter,
            };
        }
        const calcTheLoop = totalFramesToPlayLoop;

        currentFrameInLoop =
            startInFrame ?? calcTheLoop % loopMarker.durationInFrames;
        const framesToCalcForLoop = calcTheLoop - currentFrameInLoop;

        loopCounter = Math.max(
            Math.floor(framesToCalcForLoop / loopMarker.durationInFrames),
            1
        );

        remainingFramesToPlayAfterLoop = Math.max(
            totalFrames -
                loopCounter * loopMarker.durationInFrames -
                currentFrameInLoop,
            0
        );
        // loopMarker.durationInFrames - currentFrameInLoop;
        return {
            remainingFramesToPlayAfterLoop,
            currentFrameInLoop,
            loopCounter,
        };
    }

    /**
     * Plays the loop for a specified number of times with optional completion handling.
     *
     * This function allows you to play a specific section of a Lottie animation in a loop for a defined number of times.
     *
     * @param animationItem - The Lottie AnimationItem to be played.
     * @param remainingFrames - The remaining frames to play in the loop after completing the specified number of loops.
     * @param numberOfLoops - The number of times the loop should be played.
     * @param loopMarker - The loop marker obtained from the Lottie JSON, indicating the start time of the loop.
     * @param didPlayBefore - Indicates whether the animation has been played before.
     * @param startPlayFromFrame - An optional parameter specifying the frame from which to start playing the animation. If not provided, it defaults to the loopMarker's startTimeInFrames.
     *
     * @remarks
     * If `didPlayBefore` is false, the animation will be played from the specified start frame or the loopMarker's start frame.
     * After each loop, the `complete` event is used to handle the next iteration, and the final completion ensures playing the remaining frames if needed.
     *
     */
    private playLoopWithCompletion(
        animationItem: AnimationItem,
        remainingFrames: number,
        numberOfLoops: number,
        loopMarker: ILottieMarker | null,
        didPlayBefore: boolean,
        startPlayFromFrame?: number
    ) {
        this.currentLoopCounter = 1;
        let playingLoopCounter = 0;
        let isAnimationCompleted = false;
        const totalFrames = animationItem.totalFrames;
        if (!didPlayBefore) {
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndPlay(
                    startPlayFromFrame ?? loopMarker.startTimeInFrames,
                    true
                );
            });
        } else {
            /// It means that the animation played before, and we don't need to loop again.
            if (numberOfLoops === 0 || !loopMarker)
                playingLoopCounter = numberOfLoops;
        }
        animationItem.addEventListener('complete', () => {
            /// Somehow, when the play segments happens the total frames of the animation
            /// Is getting shorter (new value is the differences between the end time to start time!)
            /// if it is 0, show frame 1.
            if (isAnimationCompleted) {
                this.ngZone.runOutsideAngular(() => {
                    animationItem.goToAndStop(totalFrames, true);
                });
                return;
            }

            if (playingLoopCounter === numberOfLoops) {
                if (!loopMarker) {
                    this.ngZone.runOutsideAngular(() => {
                        animationItem.stop();
                    });
                    return;
                } else {
                    const endFrame =
                        loopMarker.startTimeInFrames + remainingFrames;
                    this.playRemainingFramesAndFreezeAtTheEnd(
                        animationItem,
                        loopMarker.startTimeInFrames,
                        endFrame
                    );
                }

                isAnimationCompleted = true;
                return;
            }
            this.ngZone.runOutsideAngular(() => {
                animationItem.goToAndPlay(loopMarker.startTimeInFrames, true);
            });
            playingLoopCounter++;
            console.log(`Loop counter ${playingLoopCounter}`);
            this.currentLoopCounter++;
        });
    }

    /**
     * Plays the remaining frames after completing loop iterations.
     *
     * @param animationItem - The Lottie AnimationItem to be played.
     * @param startFrame - The starting frame for playback.
     * @param endFrame - The ending frame for playback.
     */
    private playRemainingFramesAndFreezeAtTheEnd(
        animationItem: AnimationItem,
        startFrame: number,
        endFrame: number
    ): void {
        const frameRate = animationItem.frameRate;
        const durationInMilliseconds =
            (endFrame - startFrame) * (1000 / frameRate);
        // setTimeout(() => {
        //   animationItem.goToAndStop(endFrame, true);
        //   return;
        // }, durationInMilliseconds);
        console.log('DIFF', endFrame - startFrame);
        console.log('Total Frames:', animationItem.totalFrames);
        this.ngZone.runOutsideAngular(() => {
            animationItem.playSegments([startFrame, endFrame], false);
        });
        console.log('Total Frames:', animationItem.totalFrames);
    }

    /**
     * Sets component markers based on the provided array of Lottie JSON markers.
     *
     * @param jsonMarkers - An array of Lottie JSON markers representing keyframes or sections in a Lottie animation.
     */
    private initializeStartLoopMarkers(
        jsonMarkers: ILottieJsonMarker[],
        markers: IStartLoopMarkers
    ) {
        jsonMarkers?.forEach((jsonMarker) => {
            const lottieMarker: ILottieMarker =
                this.createLottieMarker(jsonMarker);
            switch (lottieMarker?.type) {
                case LottieMarkerTypes.START:
                    markers.startMark = lottieMarker;
                    break;
                case LottieMarkerTypes.LOOP:
                    markers.loopMark = lottieMarker;
                    break;
                default:
                    break;
            }
        });
    }

    /**
     *
     * @param animationItem
     * @returns array of markers or an empty array if none were found
     */
    getMarkers(animationItem: AnimationItem): ILottieJsonMarker[] {
        try {
            return animationItem['animationData'].markers;
        } catch (error) {
            console.error(`Could not get markers. error: ${error}`);
            return [];
        }
    }

    /**
     *
     * @param marker
     * @returns lottie marker data or null if marker is null.
     */
    createLottieMarker(marker: ILottieJsonMarker) {
        if (!marker) {
            return null;
        }
        const markerData: ILottieMarker = {
            startTimeInFrames: marker.tm,
            durationInFrames: marker.dr,
            type: JSON.parse(marker.cm).name as LottieMarkerTypes,
        };
        return markerData;
    }

    private clearEventListeners(animationItem: AnimationItem) {
        if (!animationItem) {
            console.warn(`Could not clear event listeners`);
            return;
        }

        animationItem.removeEventListener('complete');
        animationItem.removeEventListener('loopComplete');
    }

    lottieFindMarksAndPlay(animationItem: AnimationItem, ngZone: NgZone) {
        // animationItem.addEventListener('DOMLoaded', () => {
        const parsedMarkersNames =
            this.createLottieMarkersFromAnimationItem(animationItem);

        //* in case we have markers we play the animation by them .
        if (parsedMarkersNames?.length > 0) {
            const startMark = parsedMarkersNames.find(
                (mark) => mark.type === LottieMarkerTypes.START
            );
            const loopMark = parsedMarkersNames.find(
                (mark) => mark.type === LottieMarkerTypes.LOOP
            );
            if (startMark) {
                this.playLoopIfExistedWhenStartDone(animationItem, loopMark);
                ngZone.runOutsideAngular(() => {
                    animationItem.goToAndPlay(LottieMarkerTypes.START, true);
                });
            }
            //*in case we dont have start mark but only loop
            else if (loopMark) {
                ngZone.runOutsideAngular(() => {
                    animationItem.goToAndPlay(LottieMarkerTypes.LOOP, true);
                });
            }
        }
        /// In case of no marks
        else {
            this.playAnimationAndFreezeAtEnd(animationItem);
        }
        // });
    }
}
