import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { Observable } from 'rxjs';
import { Stripe } from 'stripe';

export interface IPaymentConfigs {
    text: {
        header: string;
        subHeader: string;
        body: string;
    };
}

export interface ICouponResponse {
    appliedCoupon?: IAppliedCoupon;
    error: boolean;
}

export interface IProduct {
    product: Stripe.Product;
    price: Stripe.Price;
    couponResponse: ICouponResponse;
}

export interface IAppliedCoupon {
    discountedPrice: number;
    promoCode: string;
    percentOff: number;
    amountOff: number;
    error: boolean;
    errorMessage: string;
}

export interface IProductAndPayment {
    productConfigs: IProduct;
    paymentConfigs: IPaymentConfigs;
}

@Injectable({
    providedIn: 'root',
})
export class PlansApiService {
    path = `${this.config.apiIP}/auth/plans`;
    redirectPath = `${this.config.apiIP}`;

    constructor(
        private http: HttpClient,
        private config: ConfigurationService
    ) {}

    unifiedPlan$(promoCode: string): Observable<IProductAndPayment> {
        const params = promoCode ? { promoCode } : {};

        return this.http.get<IProductAndPayment>(`${this.path}/unified`, {
            params,
        });
    }

    listPlans(): Observable<{ product: Stripe.Product; price: Stripe.Price }[]> {
        return this.http.get<any>(`${this.path}/list`);
    }

    createSetupIntent(data: { productId: string; priceId: string; name: string }) {
        return this.http.post<any>(`${this.path}/create-setup-intent`, data);
    }

    createSubscription(data: {
        setupIntentId: string;
        productId: string;
        promoCode: string;
        subscriptionFrom: string;
    }) {
        return this.http.post<any>(`${this.path}/create-subscription`, data);
    }

    confirmPayment(data: { setupIntentId: string; productId: string }) {
        return this.http.post<any>(`${this.path}/confirm-payment`, data);
    }

    //It's a temporary endpoint.
    //This endpoint is used to redirect from the payments page to the login page, so it should be a public route
    redirect() {
        return this.http.get<any>(`${this.redirectPath}/redirect-to-login`);
    }
}
